import { Subject } from 'rxjs';

export class AudioRecorderMock {

    public onStop$: Subject<Blob>;
    public error$: Subject<any>;
    public onStart$: Subject<any>;

    private _isRecording: boolean;

    constructor() {
        this.onStop$ =  new Subject();
        this.error$ = new Subject();
        this.onStart$ = new Subject();
        this._isRecording = false;
    }

    get isRecording(): boolean {
        return this._isRecording;
    }

    public startRecording(id: string): void {
        this._isRecording = true;
        this._emitOnStart();
    }

    public stopRecording(): void {
        this._isRecording = false;
        this._emitOnStop();
    }

    public emitError(): void {
        this.error$.next('error');
    }

    private _emitOnStart(): void {
        this.onStart$.next();
    }

    private _emitOnStop(): void {
        this.onStop$.next(new Blob());
    }
}
