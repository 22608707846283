import { Logger } from '@efcloud/catalyst-util/src/lib/logger';
import { Factory } from '@efcloud/catalyst-util/src/lib/factory';
import { WindowMessageBrokerFactory } from '../window-message-broker/index';
import { WiredWindow, WiredWindowConfig } from '../wired-window/wired-window';

export class WiredWindowFactory extends Factory<WiredWindow> {
    private _brokerFactory: WindowMessageBrokerFactory;
    private _logger: Logger;

    constructor(brokerFactory: WindowMessageBrokerFactory, logger: Logger) {
        super();
        this._brokerFactory = brokerFactory;
        this._logger = logger;
    }

    public createInstance(config: WiredWindowConfig): WiredWindow {
        return new WiredWindow(config, this._brokerFactory, this._logger);
    }
}
