import {Subject} from 'rxjs';
import {Observable} from 'rxjs';
import {Subscription} from 'rxjs';

import { WindowMessage } from './window-message-broker.message';

export class WindowMessenger {

    private _inMessages$: Subject<WindowMessage>;
    private _outMessages$: Subject<WindowMessage>;

    constructor(
        inMessages$: Subject<WindowMessage>,
        outMessages$: Subject<WindowMessage>
    ) {

        this._inMessages$ = inMessages$;
        this._outMessages$ = outMessages$;
    }

    public subscribe(callback: (message: WindowMessage) => void ): void {

        this._inMessages$.subscribe( ( message: WindowMessage ) => callback(message));
    }

    public sendMessage(text: string, payload: any): void {

        this._outMessages$.next({
            text,
            payload
        });
    }
}
