/**
 * Content API
 * Explore syllabi & lesson content for use in catalyst lesson service
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum LanguageFocusItemListType {
    INLINE = 'inline',
    SHORT = 'short',
    LONG = 'long'
};

