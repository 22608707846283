import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MediaRepository } from './media-repository';
import { MediaPlayer } from './player/media-player';

export interface MediaOrchestratorSubscriptions {
    [id: string]: {
        isPlaying: Subscription;
    };
}

export class MediaOrchestrator {
    private _repo: MediaRepository;
    private _subscriptions: MediaOrchestratorSubscriptions = {};

    constructor(repo: MediaRepository) {
        this._repo = repo;
    }

    public initialise(): void {
        this._repo.onItemAdded$.subscribe((mp) => this._subscribeToMediaPlayerEvents(mp));
        this._repo.onItemRemoved$.subscribe((mp) => this._clearMediaPlayerSubscriptionsById(mp.id));
    }

    public get subscriptions(): MediaOrchestratorSubscriptions {
        return this._subscriptions;
    }

    public pauseAll(): void {
        this._repo.availableItemsOrdered.forEach((mp) => mp.pause());
    }

    public destroy(): void {
        Object.keys(this._subscriptions).forEach((id) => this._clearMediaPlayerSubscriptionsById(id));

        this._repo.onItemRemoved$.unsubscribe();
        this._repo.onItemAdded$.unsubscribe();
    }

    private _subscribeToMediaPlayerEvents(mp: MediaPlayer): void {
        this._subscriptions[mp.id] = {
            isPlaying: mp.isPlaying$
                .pipe(filter((v) => !!v))
                .subscribe((isPlaying) => this._handleOnMediaStartsPlaying(mp)),
        };
    }

    private _handleOnMediaStartsPlaying(mp: MediaPlayer): void {
        this._pauseOtherMediaInstances(mp);
    }

    private _pauseOtherMediaInstances(mediaPlayer: MediaPlayer) {
        this._repo.availableItemsOrdered.filter((mp) => mp !== mediaPlayer).forEach((mp) => mp.pause());
    }

    private _clearMediaPlayerSubscriptionsById(id: string): void {
        Object.keys(this._subscriptions[id]).forEach((s) => this._subscriptions[id][s].unsubscribe());
        delete this._subscriptions[id];
    }
}
