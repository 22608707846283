import { LoggerAdaptor, LoggerConsoleAdaptor } from './adaptor/index';

/******************************************************
 *
 * Logger
 *
 ******************************************************/

export interface LoggerConfig {
    prefix?: {
        common?: string;
        log?: string;
        warn?: string;
        error?: string;
    };
}

export class Logger {
    private _config: LoggerConfig = {
        prefix: {
            common: '. ',
            log: 'LOG',
            warn: 'WARN',
            error: 'ERROR',
        },
    };

    private _adaptor: LoggerAdaptor;

    constructor(config?: LoggerConfig, adaptor?: LoggerAdaptor) {
        this._config = config || this._config;
        this._adaptor = adaptor || new LoggerConsoleAdaptor();
    }

    public log(...args): void {
        this._adaptorOut('log', args);
    }

    public warn(...args): void {
        this._adaptorOut('warn', args);
    }

    public error(...args): void {
        this._adaptorOut('error', args);
    }

    private _adaptorOut(type: string, args): void {
        this._adaptor[type].apply(this, [`${this._config.prefix.common} :: ${this._config.prefix[type]} \n`, ...args]);
    }
}
