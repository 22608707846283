import { MediaPlayer, MediaPlayerConfig } from './player/media-player';
import { AudioPlayer } from './audio-player/audio-player';
import { ShortAudioPlayer } from './short-audio-player/short-audio-player';
import { MediaRepository } from './media-repository';

export class MediaPlayerFactory {
    private _repo: MediaRepository;

    constructor(repo: MediaRepository) {
        this._repo = repo;
    }

    public createMediaPlayer(mediaElement: HTMLMediaElement, config?: MediaPlayerConfig): MediaPlayer {
        const mp = new MediaPlayer(mediaElement, config);
        this._repo.addItem(mp.id, mp);
        return mp;
    }

    public createAudioPlayer(url: string, config?: MediaPlayerConfig): MediaPlayer {
        const ap = new AudioPlayer(url, config);
        this._repo.addItem(ap.id, ap);
        return ap;
    }

    public createShortAudioPlayer(url: string, config?: MediaPlayerConfig): MediaPlayer {
        const sap = new ShortAudioPlayer(url, config);
        this._repo.addItem(sap.id, sap);
        return sap;
    }

    public destroyMediaPlayer(mp: MediaPlayer): void {
        mp.destroy();
        this._repo.removeById(mp.id);
    }
}
