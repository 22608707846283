import { Repository } from '@efcloud/catalyst-util/src/lib/repository';

export class AudioRecordingsRepository extends Repository<AudioBlob> {
    constructor() {
        super();
    }
}

export class AudioBlob {

    private readonly _blob: Blob;
    private _base64AudioString: string = '';

    constructor(blob: Blob) {
        this._blob = blob;
        this._setBase64String();
    }

    get blob(): Blob {
        return this._blob;
    }

    get base64AudioString(): string {
        return this._base64AudioString;
    }

    private _setBase64String(): void {
        const reader = new FileReader();
        reader.readAsDataURL(this._blob);
        reader.onloadend = () => {
            const audioBase64 = reader.result.toString().split(',')[1];
            this._base64AudioString = audioBase64;
        };
    }

}
