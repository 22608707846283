import { Subject, interval } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { WindowMessageBrokerConfig } from './window-message-broker.config';
import { WindowMessage } from './window-message-broker.message';
import { WindowMessenger } from './window-messenger';

export class WindowMessageBroker {
    private _channel: string;
    private _namespace: string;
    private _handshakeMessageText: string = 'handshake';
    private _destinationWindow: Window;
    private _destinationOrigin: string;
    private _poll: any = {
        interval: 100,
        maxRetries: 10,
    };

    private _inMessages$: Subject<WindowMessage> = new Subject();
    private _outMessages$: Subject<WindowMessage> = new Subject();
    private _authorisation$: Subject<WindowMessenger> = new Subject();
    private _destroy$: Subject<boolean> = new Subject();

    constructor(config: WindowMessageBrokerConfig) {
        this._channel = config.channel;
        this._namespace = config.namespace;
        this._destinationWindow = config.destination.window;
        this._destinationOrigin = config.destination.origin;

        if (config.poll) {
            this._poll.interval = config.poll.interval ? config.poll.interval : this._poll.interval;
            this._poll.maxRetries = config.poll.maxRetries ? config.poll.maxRetries : this._poll.maxRetries;
        }

        this._outMessages$
            .pipe(takeUntil(this._destroy$))
            .subscribe((message: WindowMessage) => this._sendMessage(message));

        window.addEventListener('message', this._onMessageReceived);
        window.onunload = (event) => this._onunloadHandler(event);
    }

    public requestAuthorisation(): WindowMessageBroker {
        this._authorise();

        interval(this._poll.interval)
            .pipe(
                tap(() => {
                    this._authorise();
                }),
                takeUntil(this._authorisation$),
                take(this._poll.maxRetries)
            )
            .subscribe();

        return this;
    }

    public onAuthorise(callback: (messenger: WindowMessenger) => void): void {
        this._authorisation$.pipe(take(1)).subscribe((messenger: WindowMessenger) => callback(messenger));
    }

    public destroy(): void {
        window.removeEventListener('message', this._onMessageReceived);
        window.onunload = null;

        this._inMessages$.complete();
        this._destroy$.next(true);
    }

    private _sendMessage(message: WindowMessage): void {
        if (!this._destinationOrigin) {
            throw new Error('WindowMessageBroker._sendMessage error. destination origin has not being provided.');
        }

        this._destinationWindow.postMessage(
            {
                channel: this._channel,
                namespace: this._namespace,
                text: message.text,
                payload: message.payload,
            },
            this._destinationOrigin
        );
    }

    private _onMessageReceived = (event: MessageEvent): void => {
        const message: WindowMessage = this._isKnownMessage(event) ? event.data : undefined;

        if (!message) {
            return;
        }

        if (this._isRequestingAuthorisation(message)) {
            return this._handleAuthorisationRequest(message, event.data.channel);
        }

        if (this._doesMessageBelongsToChannel(event)) {
            this._inMessages$.next(message);
        }
    };

    private _doesMessageBelongsToChannel(event): boolean {
        return this._channel === event.data.channel;
    }

    private _isKnownMessage(event: MessageEvent): boolean {
        return event && event.data && event.data.namespace === this._namespace;
    }

    private _isRequestingAuthorisation(message: WindowMessage): boolean {
        return message.text === this._handshakeMessageText;
    }

    private _handleAuthorisationRequest(message: WindowMessage, channel: string): void {
        if (!this._destinationOrigin) {
            this._channel = channel;
            this._destinationOrigin = message.payload.origin;
            this._authorise();
        }

        this._authorisation$.next(new WindowMessenger(this._inMessages$, this._outMessages$));
    }

    private _authorise(): void {
        this._sendMessage({
            text: this._handshakeMessageText,
            payload: {
                origin: window.location.origin,
            },
        });
    }

    private _onunloadHandler(event): void {
        this._inMessages$.next({
            text: 'UNLOAD',
        });
    }
}
