import { Activity, TaskDisplayType } from '../model/models';

export const activity: Activity = {
    id: 'activity-1',
    title: 'Mock title',
    instructions: 'Mock instructions',
    stimuli: [],
    drivingStimulus: undefined,
    taskDisplayType: TaskDisplayType.ONE_AT_A_TIME,
    skippable: true,
    tasks: [],
    source: undefined
};
