import { Logger } from '@efcloud/catalyst-util/src/lib/logger';
import { Repository } from '@efcloud/catalyst-util/src/lib/repository';
import { WiredWindow } from '../wired-window/wired-window';
import { WiredWindowFactory } from '../wired-window-factory/wired-window-factory';
import { OpenWindowConfig } from '../open-window-config';

export class WiredWindows {
    private _browserWindow: Window;
    private _factory: WiredWindowFactory;
    private _repository: Repository<WiredWindow>;

    constructor(browserWindow: Window, wiredWindowFactory: WiredWindowFactory, logger?: Logger) {
        this._browserWindow = browserWindow;
        this._factory = wiredWindowFactory;
        this._repository = new Repository(
            {
                id: 'wired-windows-repository',
            },
            logger
        );
    }

    public openNewWindow(id: string, url: string, config: OpenWindowConfig): WiredWindow {
        const window = this._browserWindow.open(url, id, this._parseConfig(config));
        return this._addWindow(id, window, url);
    }

    public addIframeWindow(id: string, window: Window, url: string): WiredWindow {
        return this._addWindow(id, window, url);
    }

    public addParent(): WiredWindow {
        return this._addWindow('opener', window.opener || window.parent);
    }

    public getById(id: string): WiredWindow {
        return this._repository.get(id);
    }

    public removeParent(): void {
        this.removeById('opener');
    }

    public removeById(id: string): void {
        const wiredWindow = this.getById(id);

        if (!wiredWindow) {
            return;
        }

        wiredWindow.destroy();
        this._repository.removeById(id);
    }

    private _parseConfig(config: OpenWindowConfig): string {
        return JSON.stringify(config)
            .replace(/\"/g, '')
            .replace(/:/g, '=')
            .replace(/true/g, '1')
            .replace(/false/g, '0')
            .replace(/\{(.*)\}/, '$1');
    }

    private _addWindow(id: string, window: Window, url?: string): WiredWindow {
        const wiredWindow = this._factory.createInstance({
            id,
            url,
            window,
        });

        this._repository.addItem(id, wiredWindow);

        return wiredWindow;
    }
}
