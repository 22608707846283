import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, max, pairwise, startWith, tap } from 'rxjs/operators';

export interface MediaTimeMarker {
    time: number;
    index: number;
    isPlayingForward?: boolean;
    timeWhenTriggered?: number;
}

export class MediaPlayerTimeMarkers {
    public onMarkerReached$: Observable<MediaTimeMarker>;

    private _media: HTMLMediaElement;
    private _percentages: number[] = [];
    private _markers: MediaTimeMarker[] = [];
    private _times: number[] = [];
    private _markerTimePostOffsetMs: number = 250;
    private _markerTimePreOffsetMs: number = 50;
    private _lastTimeMarkerTriggered: MediaTimeMarker;
    private _startPlayingAt: number = 0;

    constructor(media: HTMLMediaElement) {
        this._media = media;
        this._media.addEventListener('play', () => (this._startPlayingAt = this._media.currentTime * 1000));
        this.onMarkerReached$ = this._buildOnMarkerReached$();
    }

    public get percentages(): number[] {
        return this._percentages;
    }

    public get length(): number {
        return this._times.length;
    }

    public get lastTriggeredTimeMarker(): MediaTimeMarker {
        return this._lastTimeMarkerTriggered;
    }

    public getTimeMarkerByIndex(index: number): MediaTimeMarker {
        return this._markers[index];
    }

    public setTimeMarkers(timeMarkers: number[] = []): void {
        this._times = timeMarkers.filter((time) => this._media.duration * 1000 > time);
        this._markers = this._times.map((time, index) => ({ time, index }));
        this._calculateTimePercentages();
    }

    public getClosestToCurrentTime(previous: boolean = true): MediaTimeMarker {
        const currentTime = this._media.currentTime * 1000;

        if (currentTime < this._times[0]) {
            return;
        }

        const closestIndex = this._times.reduce((accum, time, index) => {
            return time < currentTime ? index : accum;
        }, 0);

        return this._markers[closestIndex];
    }

    private _calculateTimePercentages() {
        this._percentages = [];
        this._markers.forEach((timeMarker: MediaTimeMarker) => {
            const percentage = timeMarker.time / (this._media.duration * 1000);
            this._percentages.push(100 * percentage);
        });
    }

    private _buildOnMarkerReached$(): Observable<MediaTimeMarker> {
        return fromEvent(this._media, 'timeupdate').pipe(
            map((event) => (event.target as HTMLMediaElement).currentTime * 1000),
            filter((time) => {
                return (
                    time !== this._startPlayingAt &&
                    time > this._times[0] - this._markerTimePostOffsetMs &&
                    time < this._times[this._times.length - 1] + this._markerTimePostOffsetMs &&
                    Math.abs(this._startPlayingAt - time) > this._markerTimePostOffsetMs
                );
            }),
            startWith(-1),
            pairwise(),
            map(([lastTime, currentTime]) => this._findClosestTimeMarkerBetweenTimes(lastTime, currentTime)),
            filter((v) => !!v),
            tap((tm) => (this._lastTimeMarkerTriggered = tm)),
            debounceTime(this._markerTimePostOffsetMs)
        );
    }

    private _findClosestTimeMarkerBetweenTimes(lastTime: number, currentTime: number): MediaTimeMarker {
        let timeMarker: number;
        let index: number;

        lastTime = lastTime < 0 ? this._media.currentTime * 1000 : lastTime;
        const isPlayingForward: boolean = lastTime <= currentTime;

        if (lastTime === currentTime) {
            index = this._times.findIndex((t) => t === currentTime);
        } else {
            // we are looking for the first time marker in range: (now-250, now+50)
            let minTime = currentTime - this._markerTimePostOffsetMs;
            let maxTime = currentTime + this._markerTimePreOffsetMs;

            if (isPlayingForward) {
                // when playing forward we are looking for markers AFTER lastTime
                lastTime = Math.max(lastTime, 0);
                minTime = Math.max(lastTime, minTime);
            } else {
                // when playing backwards we are looking for markers BEFORE lastTime
                lastTime = lastTime > 0 ? lastTime : this._times[this._times.length - 1] + 1;
                maxTime = Math.min(lastTime, maxTime);
            }

            for (let i = 0; i < this._times.length; i++) {
                timeMarker = this._times[i];
                if (minTime < timeMarker && timeMarker < maxTime) {
                    index = i;
                    break;
                }
                // time markers are guaranteed to be sorted, so there is no point checking them after maxTime
                if (timeMarker >= maxTime) {
                    break;
                }
            }
        }

        if (index === undefined || index < 0) {
            return;
        }

        return {
            ...this._markers[index],
            timeWhenTriggered: currentTime,
            isPlayingForward,
        };
    }
}
