import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AudioBlob, AudioRecordingsRepository } from '../audio-recordings-repository';

export class AudioRecorder {

    public onStop$: Observable<Blob>;
    public error$: Subject<any>;
    public onStart$: Subject<any>;

    private _recorder: MediaRecorder;
    private _recordingsRepo: AudioRecordingsRepository;
    private _audioChunks: any[] = [];

    constructor(recRepo: AudioRecordingsRepository) {
        this._recordingsRepo = recRepo;
        this.error$ = new Subject();
        this.onStart$ = new Subject();
    }

    get isRecording(): boolean {
        return this._recorder?.state === 'recording';
    }

    public startRecording(id: string): void {
        navigator.mediaDevices.getUserMedia({audio: true})
            .then(mediaStream => {
                this._reset();
                this._recorder = new MediaRecorder(mediaStream);
                this._setupObservables(id);
                this._recorder.start();
                this.onStart$.next();
        })
        .catch(err => {
            this.error$.next(err);
        });
    }

    public stopRecording(): void {
        this._recorder.stop();
    }

    private _reset(): void {
        this._recorder = null;
        this._audioChunks = [];
    }

    private _setupObservables(id: string): void {
        (fromEvent(this._recorder, 'dataavailable') as Observable<any>)
            .pipe(take(1)).subscribe(event => this._audioChunks.push(event.data));

        this.onStop$ = fromEvent(this._recorder, 'stop')
            .pipe(
                map(() => {
                    const blob = new Blob(this._audioChunks, {type: 'audio/mpeg'});
                    this._storeRecording(id, blob);
                    this._recorder.stream.getTracks().forEach(i => i.stop());
                    return blob;
                })
            );
    }

    private _storeRecording(id: string, blob: Blob): void {
         this._recordingsRepo.addItem(id, new AudioBlob(blob));
    }

}
