/**
 * Content API
 * Explore syllabi & lesson content for use in catalyst lesson service
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum TaskType {
    MULTIPLE_CHOICE = 'multiple-choice',
    MATCHING = 'matching',
    CATEGORISATION = 'categorisation',
    LANGUAGE_FOCUS = 'language-focus',
    MEDIA_WITH_TIME_MARKERS = 'media-with-time-markers',
    SPEAKING_PRACTICE = 'speaking-practice',
    SEQUENCING = 'sequencing',
    GAPFILL = 'gapfill',
    FLASHCARDS = 'flashcards',
    WRITING_CHALLENGE = 'writing-challenge'
};

