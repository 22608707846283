import { Observable, of } from 'rxjs';
import { HttpError } from '../error/http-error';
import { HttpRequest } from '../request/http-request';
import { HttpResponse } from '../response/http-response';
import { HttpAdaptor } from './http-adaptor';

export class HttpAdaptorMock implements HttpAdaptor {
    public response: HttpResponse = {
        data: {},
        headers: {},
        status: 200,
        statusText: 'OK',
        request: {} as any,
    };

    public error: HttpError = {
        ...this.response,
        status: 500,
        statusText: 'Error',
    };

    public returnError: boolean;

    public requestMock: (request: HttpRequest) => Observable<HttpResponse | HttpError> = (r) => {
        const res = this.returnError ? this.error : this.response;
        res.request = r;
        return of(res);
    };

    public request(request: HttpRequest): Observable<HttpResponse | HttpError> {
        return this.requestMock(request);
    }
}
