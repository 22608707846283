import { Subject } from 'rxjs';

export class WiredWindowMock {
    public isConnected: boolean;
    public isDisconnected: boolean;
    public isConnecting: boolean;
    public messagesIn$: Subject<any> = new Subject();

    public connect() {
        return Promise.resolve(true);
    }

    public sendMessage() {}

    public destroy() {}
}
