import { Catalyst, LessonPlayer } from "@efcloud/catalyst-sdk";
import React, { useRef, useState } from "react";
import "./App.css";

const catalyst = new Catalyst();
let lessonPlayer: LessonPlayer;

const token =
  "eyJhbGciOiJSUzI1NiIsImtpZCI6IjQxMjFjOTIwLTkxN2EtNGU1MS04ZWRiLTc0NjNhNTU0MTcwYyIsInR5cCI6IkpXVCJ9.eyJvcmciOiJiYXIiLCJ1c2VyX2lkIjoiZm9vIn0.g_n6mjo_sFZbA-sojei3IQTNG-Kay9o9O3COffE2FUnmMrqBI1yyVJikwmO_j5Jvh-IYLyQOJbVJaolK3qnGskgBSv2bpMoIEi2nRAow1Edx7zEOMF9HYZ7TOxeHfqIovEi9OWb20frDIF3ZkLJGQKe-d17QryWvwUjqHXw72-lf3jhFd1Yipp8wNqh0Mii-qJXqJd6cqeenebXeLjg5sfEGsmT9htFlyJPh0Cey0SfU5MmWJjBBlHsokOnISfAVPmALXf_I6JcwbpxuQABBMsCm7q58uYO5jXiKHZ20EcvGLyGwWpJfYR_ctscYKWyQ7oexneoYQr6LDHwHjUlAaQ";

const PLAYER_URL = "https://lesson-player.dev.ef.studio/";

const LESSON_DATA = [
  {
    name: "Making a dinner reservation",
    id: "2d41c0a1-5162-4691-984c-7df32ee209c3",
  },
  { name: "Ordering a meal", id: "d4076e0b-5c35-4553-aa75-34e3af1d44ac" },
  {
    name: "Making polite dinner conversation",
    id: "e0ea9a08-3f6c-40d7-9cd0-023edc09ae80",
  },
  {
    name: "Ending the meal and paying the check",
    id: "a94fff75-2c3e-4b66-8ac9-8b2539692805",
  },
];

function App() {
  const [isInActivity, setIsInActity] = useState(false);
  const [activeLessonId, setActiveLessonId] = useState("");

  const iframe = useRef<null | HTMLIFrameElement>(null);
  const iframeLoaded = () => {
    if (iframe.current) {
      catalyst.connectToLessonPlayer(iframe.current).then((lp) => {
        lessonPlayer = lp;
        lessonPlayer.startLesson(activeLessonId, "en", token);

        iframe?.current?.scrollIntoView({ behavior: "smooth" });

        lessonPlayer.messagesIn$.subscribe((message) => {
          if (message.text === "STAGE_VIEW_DISPLAYED") {
            setIsInActity(true);
          } else if (message.text === "LESSON_OVERVIEW_DISPLAYED") {
            setIsInActity(false);
          }
        });
      });
    }
  };

  let lessons = LESSON_DATA.map((LESSON, index) =>
    LESSON.id !== activeLessonId ? (
      <div
        className="lesson-button"
        onClick={() => setActiveLessonId(LESSON.id)}
        key={LESSON.id}
      >
        <div className="lesson-image" />
        <div className="lesson-text">
          <div className="lesson-number">Lesson: {index + 1}</div>
          <div className="lesson-name">{LESSON.name}</div>
        </div>
      </div>
    ) : (
      <div className="lesson-player">
        <iframe
          className={`catalyst-iframe ${isInActivity ? "fullscreen" : ""}`}
          onLoad={iframeLoaded}
          scrolling="yes"
          src={PLAYER_URL}
          allow="microphone"
          ref={iframe}
          frameBorder="0"
        />
      </div>
    )
  );

  return (
    <div className="App">
      <div className="lessons-list">{lessons}</div>
    </div>
  );
}

export default App;
