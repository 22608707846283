import { MediaPlayerMock } from '../player/media-player.mock';

export class AudioPlayerMock extends MediaPlayerMock {
    readonly url: string;

    constructor(url: string) {
        super();
        this.url = url;
    }
}
