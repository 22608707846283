import { lessonPlan } from '../lesson-plan.mock';
import { activity } from '../activity.mock';
import * as DTO from '../../model/models';

export const lessonEventMocks: {
    [id: string]: DTO.EventWrapper;
} = {
    lessonStarted: {
        id: 'id',
        lessonId: 'lesson-id',
        version: 0,
        type: DTO.EventType.LESSON_STARTED,
        commandId: 'commandId',
        timestamp: '2020-03-16T10:10:0.0',
        data: {
            lessonStarted: {
                lessonId: 'lesson-id',
                org: 'catalyst',
                lessonType: DTO.LessonType.SELF_STUDY,
                languageCode: 'language-code',
                publishTag: DTO.PublishTag.PRERELEASE,
                contentId: 'content-id',
                observers: [],
                createdAt: '2020-03-16T10:10:0.0'
            }
        }
    },
    lessonPlanSet: {
        id: 'id',
        lessonId: 'lesson-id',
        version: 0,
        type: DTO.EventType.LESSON_PLAN_SET,
        commandId: 'commandId',
        timestamp: '2020-03-16T10:10:0.0',
        data: {
            lessonPlanSet: {
                lessonId: 'lesson-id',
                lessonPlan
            }
        },
    },
    lessonFinished: {
        id: 'id',
        lessonId: 'lesson-id',
        version: 0,
        type: DTO.EventType.LESSON_FINISHED,
        commandId: 'commandId',
        timestamp: '2020-03-16T10:10:0.0',
        data: {
            lessonFinished: {
                lessonId: 'lesson-id',
                updatedAt: '2020-03-16T10:10:0.0'
            }
        },
    },
    studentJoinedLesson: {
        id: 'id',
        lessonId: 'lesson-id',
        version: 0,
        type: DTO.EventType.STUDENT_JOINED_LESSON,
        commandId: 'commandId',
        timestamp: '2020-03-16T10:10:0.0',
        data: {
            studentJoinedLesson: {
                lessonId: 'lesson-id',
                sessionId: 'thread-id',
                org: 'org',
                orgUserId: 'org-user-id'
            }
        },
    },
    activitySent: {
        id: 'id',
        lessonId: 'lesson-id',
        version: 0,
        type: DTO.EventType.ACTIVITY_SENT,
        commandId: 'commandId',
        timestamp: '2020-03-16T10:10:0.0',
        data: {
            activitySent: {
                lessonId: 'lesson-id',
                stageId: 'stage-id',
                index: 0,
                activity
            }
        },
    },
    taskResponseSubmitted: {
        id: 'id',
        lessonId: 'lesson-id',
        version: 0,
        type: DTO.EventType.TASK_RESPONSE_SUBMITTED,
        commandId: 'commandId',
        timestamp: '2020-03-16T10:10:0.0',
        data: {
            taskResponseSubmitted: {
                lessonId: 'lesson-id',
                sessionId: 'session-id',
                activityId: 'activity-id',
                response: {
                    taskId: 'task-id',
                    type: DTO.TaskType.MULTIPLE_CHOICE,
                    contents: {
                        multipleChoice: {
                            contents: {
                                '0a': {
                                    id: '0a',
                                    userInput: DTO.OptionSelectionState.SELECTED,
                                    assessment: undefined
                                }
                            },
                            speechData: undefined
                        }
                    }
                }
            }
        },
    },
    taskResponseAssessed: {
        id: 'id',
        lessonId: 'lesson-id',
        version: 0,
        type: DTO.EventType.TASK_RESPONSE_ASSESSED,
        commandId: 'commandId',
        timestamp: '2020-03-16T10:10:0.0',
        data: {
            taskResponseAssessed: {
                lessonId: 'lesson-id',
                sessionId: 'session-id',
                activityId: 'activity-id',
                response: {
                    taskId: 'task-id',
                    type: DTO.TaskType.MULTIPLE_CHOICE,
                    contents: {
                        multipleChoice: {
                            contents: {
                                '0a': {
                                    id: '0a',
                                    userInput: DTO.OptionSelectionState.SELECTED,
                                    assessment: undefined
                                }
                            }
                        }
                    }
                }
            }
        },
    },
    taskSkipped: {
        id: 'id',
        lessonId: 'lesson-id',
        version: 0,
        type: DTO.EventType.TASK_SKIPPED,
        commandId: 'commandId',
        timestamp: '2020-03-16T10:10:0.0',
        data: {
            taskSkipped: {
                lessonId: 'lesson-id',
                sessionId: 'session-id',
                activityId: 'activity-id',
                taskId: 'task-id'
            }
        },
    },
    activityCompleted: {
        id: 'id',
        lessonId: 'lesson-id',
        version: 0,
        type: DTO.EventType.ACTIVITY_COMPLETED,
        commandId: 'commandId',
        timestamp: '2020-03-16T10:10:0.0',
        data: {
            activityCompleted: {
                lessonId: 'lesson-id',
                sessionId: 'session-id',
                activityId: 'activity-id',
            }
        },
    },
};
