import * as DTO from '../model/models';


export const lessonPlan: DTO.LessonPlan = {
    coverImage: undefined,
    currentLocale: 'en-US',
    contentType: DTO.LessonPlanContentType.ONLY_ACTIVITIES,
    content: {
        activities: [
            {
                id: '53882a97-69ae-49f6-b95c-94f46d824abc',
                instructions: '<p>Simple instructions</p>',
                skippable: true,
                stimuli: null,
                taskDisplayType: DTO.TaskDisplayType.ONE_AT_A_TIME,
                tasks: [
                    {
                        expectedResponse: {
                            contents: {
                                multipleChoice: {
                                    contents: {
                                        '2c730bb2-ab1c-49b3-9922-fb69d59640d5': {
                                            id: '2c730bb2-ab1c-49b3-9922-fb69d59640d5',
                                            userInput: DTO.OptionSelectionState.SELECTED
                                        },
                                        'a56a6fc2-eb58-4adf-9093-d92c3e79a7d9': {
                                            id: 'a56a6fc2-eb58-4adf-9093-d92c3e79a7d9',
                                            userInput: DTO.OptionSelectionState.NOT_SELECTED
                                        }
                                    }
                                }
                            },
                            taskId: 'd4f79225-9fa8-4857-b0cd-f508c1506926',
                            type: DTO.TaskType.MULTIPLE_CHOICE
                        },
                        feedbackMaxAttempts: 1,
                        feedbackMode: DTO.FeedbackMode.ALLOW_N_ATTEMPTS,
                        feedbackScope: DTO.FeedbackScope.TASK,
                        id: 'd4f79225-9fa8-4857-b0cd-f508c1506926',
                        prompt: '<p>Simple question</p>',
                        stimuli: [],
                        task: {
                            multipleChoice: {
                                allowMultipleSelect: false,
                                displayOptionsInline: false,
                                interactionType: DTO.InteractionType.TAP,
                                responseOptions: [
                                    {
                                        content: {
                                            content: {
                                                plainText: {
                                                    text: 'Correct answer'
                                                }
                                            },
                                            id: 'ebadc0aa-91b3-4048-8dd5-c4f0727fe5e0',
                                            type: DTO.StimulusTypeEnum.PLAIN_TEXT
                                        },
                                        id: '2c730bb2-ab1c-49b3-9922-fb69d59640d5'
                                    },
                                    {
                                        content: {
                                            content: {
                                                plainText: {
                                                    text: 'Incorrect answer'
                                                }
                                            },
                                            id: '20061ae6-e337-4ddd-9c24-cd2dba1759fa',
                                            type: DTO.StimulusTypeEnum.PLAIN_TEXT
                                        },
                                        id: 'a56a6fc2-eb58-4adf-9093-d92c3e79a7d9'
                                    }
                                ],
                                showSequenceNumbers: false
                            }
                        },
                        taskType: DTO.TaskType.MULTIPLE_CHOICE
                    }
                ],
                title: 'Simple lesson plan title'
            }
        ],
        stages: []
    },
    category: 'Reading',
    description: '',
    id: '74960bd5-78f9-450c-8a27-5d27618c79d9',
    source: {
        id: '42',
        name: 'Courseware',
        version: '1.0'
    },
    title: 'The simplest lesson',
    publishedVersion: 1,
    schemaVersion: 1,
};

