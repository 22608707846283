import { BehaviorSubject, fromEvent, merge, Observable, Subject, Subscription } from 'rxjs';
import {
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    map,
    pairwise,
    startWith,
    take,
    tap,
    takeUntil,
} from 'rxjs/operators';
import { UUID } from '@efcloud/catalyst-util/src/lib/uuid';
import { MediaPlayerTimeMarkers, MediaTimeMarker } from './time-markers/media-player-time-markers';

export enum EF_MEDIA_ERRORS {
    NOT_MEDIA_ELEMENT = 'The element provided is not a valid media',
}

export interface MediaPlayerConfig {
    pauseOnMarkerReached?: boolean;
}

export class MediaPlayer {
    public id: string;
    public onLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public onEnded$: Observable<any>;
    public onSubtitleChange$: Observable<string>;
    public onMarkerReached$: Subject<MediaTimeMarker> = new Subject();
    public displayTime$: Observable<number>;
    public duration$: BehaviorSubject<number> = new BehaviorSubject(0);
    public currentTime$: Observable<number>;
    public isPlaying$: Observable<boolean>;
    public isSeeking$: Subject<boolean> = new Subject();
    public onToggleMute$: Observable<boolean>;
    public hasSubtitles$: Subject<boolean> = new Subject();
    public hasSubtitles: boolean;

    private _media: HTMLMediaElement;
    private _timeMarkers: MediaPlayerTimeMarkers;
    private _isSeeking: boolean;
    private _wasPlayingWhenSeekStarted: boolean;
    private _onEndedSubscription: Subscription;
    private _currentTrack: HTMLTrackElement;
    private _config: MediaPlayerConfig = {
        pauseOnMarkerReached: false,
    };
    private _unsubscribe$: Subject<void> = new Subject();
    private _hasPausedOnMarkerReached: boolean = false;

    constructor(mediaElement: HTMLMediaElement, config?: MediaPlayerConfig) {
        if (!this._isMediaElement(mediaElement)) {
            throw new Error(EF_MEDIA_ERRORS.NOT_MEDIA_ELEMENT);
        }

        if (!!config) {
            this.configure(config);
        }

        this._media = mediaElement;
        this.id = UUID.generateUUID();
        this._setIsSeeking(false);
        this._wasPlayingWhenSeekStarted = false;

        this._setupObservables();
    }

    get src(): string {
        return this._media.src;
    }

    get duration(): number {
        return this._media.duration || 0;
    }

    get currentTime(): number {
        return this._media.currentTime;
    }

    get isPlaying(): boolean {
        return !this._media.paused || (this._isSeeking && this._wasPlayingWhenSeekStarted);
    }

    get isMuted(): boolean {
        return this._media.muted;
    }

    get timeMarkerPercentages(): number[] {
        return this._timeMarkers?.percentages;
    }

    get timeMarkersLength(): number {
        return this._timeMarkers?.length ?? 0;
    }

    public configure(config: MediaPlayerConfig): void {
        this._config = {
            ...this._config,
            ...config,
        };
    }

    public setSrc(url: string) {
        this._media.src = url;
        this._unsubscribe$.next();
        this._setupObservables();
    }

    public play(): void {
        this._media.play().catch((err) => {
            return err;
        });

        this._hasPausedOnMarkerReached = false;
    }

    public pause(): void {
        this._media.pause();
        this._hasPausedOnMarkerReached = false;
    }

    public restart(): void {
        this._media.currentTime = 0;
    }

    public togglePlay(): void {
        if (this.isPlaying) {
            this.pause();
        } else {
            this.play();
        }
    }

    public playPreviousSegment(): void {
        const closestTime = this._timeMarkers.getClosestToCurrentTime()?.index ?? -1;
        const indexToPlayFrom = this._hasPausedOnMarkerReached ? closestTime - 1 : closestTime;

        this.playFromMarkerByIndex(indexToPlayFrom);
    }

    public playFromMarkerByIndex(index: number): void {
        if (index > -1 && index <= this._timeMarkers.length - 1) {
            this.seek(this._timeMarkers.getTimeMarkerByIndex(index).time / 1000, true);
        } else {
            this.seek(0, true);
        }

        this.play();
    }

    public playFromPreviousMarker(): void {
        const timeMarker = this._timeMarkers.getClosestToCurrentTime();
        const timeToPlayFrom = (timeMarker?.time ?? 0) + 1;

        this.seek(timeToPlayFrom / 1000, true);
        this.play();
    }

    public playFromPreviousMarkerByIndex(index: number): void {
        if (index > 0 && index <= this._timeMarkers.length - 1) {
            this.seek(this._timeMarkers.getTimeMarkerByIndex(index - 1).time / 1000, true);
        } else {
            this.seek(0, true);
        }

        this.play();
    }

    public mute(): void {
        this._media.muted = true;
    }

    public unmute(): void {
        this._media.muted = false;
    }

    public toggleMute(): void {
        if (this.isMuted) {
            this.unmute();
        } else {
            this.mute();
        }
    }

    public destroy(): void {
        this._media.removeAttribute('src');
        this._media.load();
        if (this._onEndedSubscription) {
            this._onEndedSubscription.unsubscribe();
            this._onEndedSubscription = null;
        }
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
        this.onMarkerReached$.complete();
        this.hasSubtitles$.complete();
        this.isSeeking$.complete();
    }

    public seek(time: number, released: boolean = false): void {
        if (this._onEndedSubscription) {
            this._onEndedSubscription.unsubscribe();
            this._onEndedSubscription = null;
        }

        if (!this._isSeeking) {
            this._setIsSeeking(true);
            this._wasPlayingWhenSeekStarted = this.isPlaying;
            this.pause();
        }

        this._media.currentTime = time;

        if (!released) {
            return;
        }

        this._onEndedSubscription = this.onEnded$.subscribe();

        this._setIsSeeking(false);

        if (!this._wasPlayingWhenSeekStarted) {
            return;
        }

        this._wasPlayingWhenSeekStarted = false;

        if (Math.round(10 * this.duration) === Math.round(10 * this.currentTime)) {
            return;
        }

        this.play();
    }

    public addSubtitles(subtitlesUrl: string): void {
        if (this.hasSubtitles) {
            this._media.removeChild(this._currentTrack);
            this.hasSubtitles = false;
        }

        if (subtitlesUrl !== '') {
            this._currentTrack = this._createTrack(subtitlesUrl);
            this._media.appendChild(this._currentTrack);
            this.hasSubtitles = true;
        }

        this._updateHasSubtitle(this.hasSubtitles);

        if (this._media.textTracks.length > 0) {
            const track = this._media.textTracks[0];

            this.onSubtitleChange$ = fromEvent(track, 'cuechange').pipe(
                map(() => {
                    if (!!track.activeCues[0]) {
                        return (track.activeCues[0] as VTTCue).text;
                    } else {
                        return '';
                    }
                })
            );
        }
    }

    public setTimeMarkers(timeMarkers: number[] = []): void {
        if (!this._timeMarkers) {
            this._timeMarkers = new MediaPlayerTimeMarkers(this._media);
            this._timeMarkers.onMarkerReached$
                .pipe(takeUntil(this._unsubscribe$))
                .subscribe((timeMarker) => this._handleOnMarkerReached(timeMarker));
        }

        this._timeMarkers.setTimeMarkers(timeMarkers);
    }

    private _handleOnMarkerReached(tm: MediaTimeMarker): void {
        if (this._config.pauseOnMarkerReached) {
            this.pause();
            this._hasPausedOnMarkerReached = true;
        }
        this.onMarkerReached$.next(tm);
    }

    private _setIsSeeking(isSeeking: boolean): void {
        this._isSeeking = isSeeking;
        this.isSeeking$.next(isSeeking);
    }

    private _updateHasSubtitle(hasSubtitles: boolean): void {
        this.hasSubtitles$.next(hasSubtitles);
    }

    private _createTrack(subtitlesUrl: string): HTMLTrackElement {
        const track = document.createElement('track');
        track.kind = 'subtitles';
        track.label = 'English';
        track.srclang = 'en';
        track.src = subtitlesUrl;
        track.track.mode = 'disabled';

        return track;
    }

    private _setupObservables(): void {
        merge(fromEvent(this._media, 'loadedmetadata'), fromEvent(this._media, 'durationchange'))
            .pipe(
                filter(() => this.duration !== Infinity),
                take(1))
            .subscribe(() => {
                this.duration$.next(this.duration);
                this.onLoaded$.next(true);
            });

        this.onEnded$ = fromEvent(this._media, 'ended').pipe(filter(() => !this._isSeeking));

        this._onEndedSubscription = this.onEnded$.subscribe();

        this.isPlaying$ = merge(
            fromEvent(this._media, 'play').pipe(map(() => true)),
            fromEvent(this._media, 'pause').pipe(map(() => false))
        ).pipe(startWith(false));

        this.onToggleMute$ = fromEvent(this._media, 'volumechange').pipe(
            map(() => this.isMuted),
            startWith(false)
        );

        this.currentTime$ = fromEvent(this._media, 'timeupdate').pipe(
            map((event: Event) => (event.target as HTMLMediaElement).currentTime)
        );

        this.displayTime$ = this.currentTime$.pipe(
            map((time) => Math.floor(time)),
            distinctUntilChanged()
        );
    }

    private _isMediaElement(element: HTMLMediaElement) {
        return (
            element !== null &&
            element.nodeName !== undefined &&
            (element.nodeName.toLocaleLowerCase() === 'video' || element.nodeName.toLocaleLowerCase() === 'audio')
        );
    }
}
