import { HttpResponse } from '../response/http-response';
import { HttpRequest } from '../request/http-request';

export class HttpTypeGuards {
    public static isHttpRequest(arg: any): arg is HttpRequest {
        return 'method' in arg && 'url' in arg;
    }

    public static isHttpResponse(arg: any): arg is HttpResponse {
        return 'status' in arg;
    }
}
