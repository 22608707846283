import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UUID } from '@efcloud/catalyst-util/src/lib/uuid';
import { MediaPlayerConfig } from './media-player';
import { MediaTimeMarker } from './time-markers/media-player-time-markers';

export class MediaPlayerMock {
    public isSeeking$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public onLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isPlaying$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public onToggleMute$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public displayTime$: BehaviorSubject<number> = new BehaviorSubject(0);
    public duration$: BehaviorSubject<number> = new BehaviorSubject(0);
    public currentTime$: BehaviorSubject<number> = new BehaviorSubject(0);
    public onSubtitleChange$: Subject<string> = new Subject();
    public onMarkerReached$: Subject<MediaTimeMarker> = new Subject();
    public onEnded$: Subject<any> = new Subject();
    public onSeeking: Subject<number> = new Subject();
    public hasSubtitles$: Subject<boolean> = new Subject();

    public id: string;
    public src: string;
    public isSeeking: boolean = false;
    public isPlaying: boolean = false;
    public isMuted: boolean = false;
    public subtitle: string = '';
    public duration: number = 0;
    public currentTime: number = 0;
    public timeMarkerPercentages: number[] = [];
    public hasSubtitles: boolean = false;
    public timeMarkersLength: number = 0;

    constructor(src: string = 'media-player.mock') {
        this.src = src;
        this.id = UUID.generateUUID();
    }

    public pause(): void {
        this.isPlaying = false;
        this._emitIsPlaying$();
    }

    public play(): void {
        this.isPlaying = true;
        this._emitIsPlaying$();
    }

    public mute(): void {
        this.isMuted = true;
    }

    public unmute(): void {
        this.isMuted = false;
    }

    public restart(): void {
        this.setCurrTime(0);
    }

    public setCurrTime(time: number): void {
        this.currentTime = time;
    }

    public playPreviousSegment(): void {
        this.play();
    }
    public playFromPreviousMarkerByIndex(index: number): void {
        this.play();
    }
    public playFromMarkerByIndex(index: number): void {
        this.play();
    }

    public setTimeMarkers(timeMarkers: number[] = []): void {}

    public configure(config: MediaPlayerConfig): void {}

    public setSrc(src: string): void {
        this.src = src;
    }

    public togglePlay(): void {
        this.isPlaying = !this.isPlaying;
        this._emitIsPlaying$();
    }

    public playFromPreviousMarker(): void {
        this.setCurrTime(0);
        this.play();
    }

    public emitOnMarker(time: number, index: number): void {
        this._emitOnMarker$(time, index);
    }

    public finishPlaying(): void {
        this.isPlaying = false;
        this._emitIsPlaying$();
    }

    public emitSubtitle(text: string): void {
        this._emitOnSubtitleChange$(text);
    }

    public addSubtitles(subtitlesUrl: string): void {
        this.hasSubtitles = !(subtitlesUrl === '');
        this.hasSubtitles$.next(this.hasSubtitles);
    }

    public seek(time: number, released: boolean): void {}

    public destroy(): void {}

    public toggleMute(): void {
        this.isMuted = !this.isMuted;
        this._emitOnToggleMute$();
    }

    public emitIsPlaying$(): void {
        this._emitIsPlaying$();
    }

    public emitOnEnded$(): void {
        this._emitOnEnded$();
    }

    public emitDisplayTime$(seconds: number): void {
        this.displayTime$.next(seconds);
    }

    public emitOnLoaded$(isLoaded: boolean = true): void {
        this.onLoaded$.next(true);
    }

    public emitDuration$(duration: number): void {
        this.duration$.next(duration);
    }

    private _emitOnToggleMute$(): void {
        this.onToggleMute$.next(this.isMuted);
    }

    private _emitIsPlaying$(): void {
        this.isPlaying$.next(this.isPlaying);
    }

    private _emitOnEnded$(): void {
        this.onEnded$.next(true);
    }


    private _emitOnMarker$(time: number, index: number): void {
        this.onMarkerReached$.next({
            time,
            index,
        });
    }

    private _emitOnSubtitleChange$(text: string): void {
        this.onSubtitleChange$.next(text);
    }
}
