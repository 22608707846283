import { LoggerAdaptor } from '../logger-adaptor';

/******************************************************
 *
 * LoggerConsoleAdaptor
 *
 ******************************************************/

export class LoggerConsoleAdaptor implements LoggerAdaptor {
    public log(...args): void {
        console.log.apply(this, args);
    }

    public warn(...args): void {
        console.warn.apply(this, args);
    }

    public error(...args): void {
        console.error.apply(this, args);
    }
}
