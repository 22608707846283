/**
 * lesson API
 * Explore syllabi & lesson content for use in catalyst lesson service
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum EventType {
    LESSON_STARTED = 'lesson-started',
    LESSON_PLAN_SET = 'lesson-plan-set',
    LESSON_FINISHED = 'lesson-finished',
    STUDENT_JOINED_LESSON = 'student-joined-lesson',
    ACTIVITY_SENT = 'activity-sent',
    TASK_RESPONSE_SUBMITTED = 'task-response-submitted',
    TASK_RESPONSE_ASSESSED = 'task-response-assessed',
    TASK_PASSED = 'task-passed',
    TASK_COMPLETED = 'task-completed',
    TASK_SKIPPED = 'task-skipped',
    ACTIVITY_COMPLETED = 'activity-completed'
};

