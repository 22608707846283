export class StringUtils {

    public static replaceMultiple(source: string, targetValues: string[], values: string[]): string {
        let result: string = source;

        targetValues.forEach((targetValue: string, index: number) => {
            result = result.replace(targetValue, values[index]);
        });

        return result;
    }

    public static transformKebabCaseToCamelCase(input: string): string {
        const r = input.split('-')
            .filter( str => str.length > 0 )
            .map(
                (item, index) => index
                    ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
                    : item.toLowerCase()
            ).join('');
        return r;
    }

    // stripSpecialCharacters removes all non-alphanumerical and non-whitespace characters from the input string
    public static stripSpecialCharacters(input: string): string {
        let result: string = input;

        result = result.replace(/[^(\p{L}|\p{N}|\s)]/ug, '');

        return result;
    }
}
