import { Subject } from 'rxjs';
import { Logger } from '@efcloud/catalyst-util/src/lib/logger';
import { RepositoryConfig } from './repository.config';

const ERRORS = {
    ITEM_OVERWRITE: ` Repo`,
};

export class Repository<T> {
    public onItemAdded$: Subject<T> = new Subject();
    public onItemRemoved$: Subject<T> = new Subject();

    private _collection: Map<string, T>;
    private _orderedCollection: T[];
    private _id: string;
    private _fallbackKey: string;
    private _allowItemOverwrite: boolean = true;
    private _logger: Logger;

    constructor(config?: RepositoryConfig, logger?: Logger) {
        this._collection = new Map();
        this._orderedCollection = [];
        this._id = config && config.id ? config.id : 'Repository';
        this._fallbackKey = config?.fallbackKey ?? undefined;
        this._allowItemOverwrite = config?.allowItemOverwrite ?? true;
        this._logger = logger || new Logger();
    }

    public get fallbackValue(): T {
        return this._fallbackKey ? this._collection.get(this._fallbackKey) : undefined;
    }

    public get availableItemsOrdered(): T[] {
        return this._orderedCollection;
    }

    public get availableItems(): string[] {
        const items = [];

        this._collection.forEach((value: T, key: string) => items.push(key));

        return items.sort();
    }

    public addItem(id: string, item: T): void {
        if (!this._allowItemOverwrite && this._collection.get(id)) {
            this._logger.warn(`
                ${this._id}.ItemOverwrite Error.
                The repository is configured to not allow overwrites.

                Trying to overwrite item with id: ${id}
            `);

            return;
        }

        this._collection.set(id, item);
        this._orderedCollection.push(item);
        this.onItemAdded$.next(item);
    }

    public addByMap(map: any = {}): void {
        Object.keys(map).forEach((key) => this.addItem(key, map[key]));
    }

    public get(id: string): T {
        const fallbackValue = this.fallbackValue;

        if (!id) {
            if (!fallbackValue) {
                this._handleUndefinedIdError(id, 'get(id: string)');
            }

            return fallbackValue;
        }

        const item = this._collection.get(id);

        if (!item) {
            this._handleElementNotFoundError(id);
            return this.fallbackValue;
        }

        return item;
    }

    public removeById(id: string): void {
        const item = this._collection.get(id);
        this._collection.delete(id);
        this._orderedCollection = this._orderedCollection.filter((elem) => elem !== item);
        this.onItemRemoved$.next(item);
    }

    private _handleElementNotFoundError(id: string): void {
        this._logger.warn(`
        ${this._id}.ItemNotFoundError
        Item "${id}" is not in the repository,

        AvaliableItems:

        ${this.availableItems.join(`
        `)}
        `);
    }

    private _handleUndefinedIdError(id: string, methodsName: string): void {
        this._logger.warn(`
        ${this._id}. ${methodsName} invoked with an undefined id.

        AvaliableItems:

        ${this.availableItems.join(`
        `)}`);
    }
}
