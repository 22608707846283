export class WiredWindowsMock {
    private _wiredWindowMock: any;
    private _wiredWindowPromiseMock: any;
    private _promiseHandlers: any = {};

    public setWiredWindowMock(window: any): void {
        this._wiredWindowMock = window;
    }

    public resolvePromise(): void {
        this._promiseHandlers.resolve(this._wiredWindowMock);
    }

    public openNewWindow(id: string, url: string, config: any): any {
        return this._wiredWindowMock;
    }

    public addIframeWindow(id: string, window: Window, url: string): any {
        return this._wiredWindowMock;
    }

    public addParent(): any {
        return this._wiredWindowMock;
    }

    public getById(id: string): any {
        return this._wiredWindowMock;
    }

    public removeParent(): void {}

    public removeById(id: string): void {}
}
