/**
 * lesson API
 * Explore syllabi & lesson content for use in catalyst lesson service
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum CommandType {
    START_SELF_STUDY = 'start-self-study',
    SEND_ACTIVITY = 'send-activity',
    SUBMIT_ACTIVITY_RESPONSE = 'submit-activity-response',
    SUBMIT_TASK_RESPONSE = 'submit-task-response',
    SKIP_TASK = 'skip-task',
    COMPLETE_ACTIVITY = 'complete-activity'
};

