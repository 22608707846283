/**
 * Content API
 * Explore syllabi & lesson content for use in catalyst lesson service
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StimulusContent } from './stimulusContent.gen';


export interface Stimulus { 
    id: string;
    type: StimulusTypeEnum;
    content: StimulusContent;
}
export enum StimulusTypeEnum {
    IMAGE = 'image',
    VIDEO = 'video',
    AUDIO = 'audio',
    IMAGE_AUDIO = 'image-audio',
    URL = 'url',
    RICH_TEXT = 'rich-text',
    PLAIN_TEXT = 'plain-text',
    NOTEPAD = 'notepad',
    WEBSITE = 'website',
    MAGAZINE = 'magazine',
    NEWSPAPER = 'newspaper',
    EMAIL_THREAD = 'email-thread'
};



