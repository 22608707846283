/**
 * Content API
 * Explore syllabi & lesson content for use in catalyst lesson service
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum TaskDisplayType {
    ONE_AT_A_TIME = 'one-at-a-time',
    STIMULUS_DRIVEN = 'stimulus-driven'
};

