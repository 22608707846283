import { StringUtils } from '@efcloud/catalyst-util/src/lib/string-utils';
import * as deepMerge from 'deepmerge';

export const MISSING_OBJECT_PROP_ERROR = 'ObjectUtils:: There is no object \'%propertyName%\'. Failed at prop \'%prop%\'';

export class ObjectUtils {
    public static clone<T>(source: T): T {
        return JSON.parse(JSON.stringify(source));
    }

    public static merge(target: any, target1: any): void {
        Object.keys(target1).forEach((key: string) => {
            target[key] = target1[key];
        });
    }

    public static deepMerge(args): any {
        const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

        return deepMerge.all(args, {
            arrayMerge: overwriteMerge,
        });
    }

    public static deepAssign(...args): any {
        return ObjectUtils.deepMerge(args);
    }

    public static recursivelyFindProperty(propertyName: string, target: any): any {
        propertyName.split('.').forEach((prop: string) => {
            target = target[prop];

            if (target === undefined) {
                ObjectUtils.throwMissingPropertyError(propertyName, prop);
            }
        });

        return target;
    }

    public static throwMissingPropertyError(propertyName: string, prop: string): void {
        const msg = StringUtils.replaceMultiple(
            MISSING_OBJECT_PROP_ERROR, ['%propertyName%', '%prop%'], [propertyName, prop]
        );

        throw new Error(msg);
    }

    public static isPropertyChainDefined(obj, path) {
        const chainedProperties = path.split('.');

        const res =
            chainedProperties.length > 0 ?
            chainedProperties.reduce((accumulator, property) => accumulator[property] || 0, obj) :
            obj[path];

        return !!res ? res : false;
    }

    public static mergeTrees(a: any, b: any): any {
        if (!b || !b.children) {
            return a;
        }

        const aIdList = a.children.map((i) => i.id);

        b.children.forEach((c) => {
            if (!aIdList.includes(c.id)) {
                a.children.push(c);
            } else {
                let item = a.children.filter((ch) => ch.id === c.id)[0];
                item = ObjectUtils.mergeTrees(item, c);
            }
        });

        return a;
    }

    public static convertArrayToObjectByAttribute(array: any[], attribute: string): any {

        // NOTE not all cases are covered, this assumes all the objects in the array contains the attribute passed and they are note repeated
        const result = array.reduce((acum, current) => ({
            ...acum,
            ...{
                [current[attribute]]: current
            }
        }), {});

        return result;
    };
}
