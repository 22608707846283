import {
    Subject,
    Observer,
    Observable,
    Subscriber
} from 'rxjs';
import { Logger } from '@efcloud/catalyst-util/src/lib/logger';
import {
    WindowMessage,
    WindowMessageBrokerFactory,
    WindowMessenger,
    WindowMessageBroker,
} from '../window-message-broker/index';

export enum WIRED_WINDOW_STATE {
    DISCONNECTED = 'DISCONNECTED',
        CONNECTED = 'CONNECTED',
        CONNECTING = 'CONNECTING',
}

const ERRORS = {
    TRY_TO_CONNECT_WHEN_NOT_DISCONNECTED: `
    WiredWindow.connect() Error. Attempting to connect while whe WiredWindow is not disconnected
    `,
    SEND_MESSAGE_WHEN_NOT_CONNECTED: `
    WiredWindow.connect() Error. Attempting to send a message when the WiredWindow is not connected
    `,
};

export interface WiredWindowConfig {
    id: string;
    url: string;
    window: Window;
}

export class WiredWindow {

    public messagesIn$: Subject < WindowMessage > ;

    private _broker: WindowMessageBroker;
    private _brokerFactory: WindowMessageBrokerFactory;
    private _messenger: WindowMessenger;
    private _logger: Logger;
    private _config: WiredWindowConfig;

    private _state: WIRED_WINDOW_STATE = WIRED_WINDOW_STATE.DISCONNECTED;
    private _messegeBuffer: any[] = [];

    constructor(config: WiredWindowConfig, brokerFactory: WindowMessageBrokerFactory, logger: Logger) {
        this.messagesIn$ = new Subject();
        this._brokerFactory = brokerFactory;
        this._logger = logger;
        this._config = config;
    }

    public connect(): Promise<boolean> {
        if (!this.isDisconnected) {
            this._logger.warn(ERRORS.TRY_TO_CONNECT_WHEN_NOT_DISCONNECTED);
            return;
        }

        this._state = WIRED_WINDOW_STATE.CONNECTING;

        return this._establishConnection();
    }

    public get id(): string  { return this._config.id; }
    public get isConnected(): boolean { return this._state === WIRED_WINDOW_STATE.CONNECTED; }
    public get isDisconnected(): boolean { return this._state === WIRED_WINDOW_STATE.DISCONNECTED; }
    public get isConnecting(): boolean { return this._state === WIRED_WINDOW_STATE.CONNECTING; }

    public sendMessage(text: string, payload: any): void {
        if (!this.isConnected) {
            this._messegeBuffer.push({
                text,
                payload
            });
            return;
        }

        this._messenger.sendMessage(text, payload);
    }

    public destroy(): void {
        this._broker.destroy();
        this._messenger = null;
    }

    private _establishConnection(): Promise < boolean > {
        let resolveCallback;
        const result: Promise < boolean > = new Promise((resolve) => (resolveCallback = resolve));

        this._broker = this._brokerFactory.createInstance({
            channel: this._config.id,
            namespace: 'ef-catalyst',
            destination: {
                window: this._config.window,
                origin: this._config.url,
            },
        });

        if (this._config.url) {
            this._broker.requestAuthorisation();
        }

        this._broker.onAuthorise((messenger: WindowMessenger) => {
            this._setMessenger(messenger);
            this._state = WIRED_WINDOW_STATE.CONNECTED;
            this._sendBufferedMessages();
            resolveCallback(true);
        });

        return result;
    }

    private _sendBufferedMessages(): void {
        for (const msg of this._messegeBuffer) {
            this.sendMessage(msg.text, msg.payload);
        }

        this._messegeBuffer = [];
    }

    private _setMessenger(messenger: WindowMessenger): void {
        this._messenger = messenger;
        this._messenger.subscribe((msg) => this._onMessageIn(msg));
    }

    private _onMessageIn(message: WindowMessage): void {
        this.messagesIn$.next(message);
    }
}
